<template>
  <div class="">
    <div class="flex items-center h-full fixed right-0">
      <button
        @click="toggle"
        class="
          bg-dima
          w-10
          py-1
          text-white
          font-extrabold
          text-3xl
          rounded-l-xl
          flex
          justify-center
          items-center
          focus:outline-none
        "
      >
        +
      </button>
    </div>
    <section
      v-if="info"
      id="info"
      class="bg-dima inset-0 absolute z-50 overflow-y-scroll"
    >
      <div
        class="max-w-8xl mx-auto py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0"
      >
        <button
          @click="toggle"
          class="w-6 h-6 rounded-full left-0 focus:outline-none"
        >
          <svg
            :class="`${$i18n.locale}` == 'ar' ? '' : 'transform rotate-180'"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 490.667 490.667"
            style="enable-background: new 0 0 490.667 490.667"
            xml:space="preserve"
          >
            <path
              style="fill: white"
              d="M245.333,0C109.839,0,0,109.839,0,245.333s109.839,245.333,245.333,245.333
            s245.333-109.839,245.333-245.333C490.514,109.903,380.764,0.153,245.333,0z"
            />
            <path
              style="fill: #87cadb"
              d="M290.133,104.533c-19.139-18.289-49.277-18.289-68.416,0c-18.893,18.881-18.902,49.503-0.021,68.395
            c0.007,0.007,0.014,0.014,0.021,0.021l19.2,19.2H117.333C87.878,192.149,64,216.027,64,245.483
            c0,29.455,23.878,53.333,53.333,53.333h123.584l-19.2,19.2c-17.522,20.175-15.371,50.734,4.804,68.257
            c18.259,15.858,45.423,15.799,63.612-0.139l118.251-118.251c12.492-12.496,12.492-32.752,0-45.248L290.133,104.533z"
            />
          </svg>
        </button>
        <div class="mt-6 md:mt-10 lg:mt-16 lg:flex">
          <div class="lg:w-3/12 text-white text-lg">
            <div class="fontBold text-2xl md:mb-2">
              + {{ $t("Dima.project_info.project_info") }}
            </div>
            <br />
            {{ $t("Dima.project_info.work.1") }} /
            <br class="hidden lg:block" />
            {{ $t("Dima.project_info.work.2") }} /
            <br class="hidden lg:block" />
            {{ $t("Dima.project_info.work.3") }} /
            <br class="hidden lg:block" />
            {{ $t("Dima.project_info.work.4") }} /
          </div>
          <div class="lg:w-9/12 text-white mt-6">
            <p class="text-4xl fontBold">
              {{ $t("Dima.project_info.description.title") }}
            </p>

            <div class="">
              <p class="mt-6 md:text-justify leading-7 text-lg">
                {{ $t("Dima.project_info.description.content_1") }}
              </p>

              <p class="mt-6 md:text-justify leading-7 text-lg">
                {{ $t("Dima.project_info.description.content_2") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="hero" class="w-full h-screen relative bg-center">
      <div class="flex justify-center items-center h-full">
        <img src="../assets/images/dima/DIMA_LOGO.svg" alt="" class="w-1/3" />
      </div>
    </section>

    <section id="fresh" class="background-ELEMENTS_dima bg-no-repeat bg-cover">
      <div class="max-w-8xl mx-auto py-20 px-6 md:px-8 lg:px-12 xl:px-0">
        <p
          class="
            text-white text-4xl
            lg:text-6xl
            xl:text-9xl
            font-black
            max-w-2xl
            fontBold
            lg:tracking-ls
          "
        >
          {{ $t("Dima.fresh.title_1") }}
        </p>

        <div class="mt-20 md:mt-64">
          <p
            class="
              text-white text-4xl
              lg:text-6xl
              xl:text-9xl
              font-black
              max-w-2xl
              fontBold
              lg:tracking-ls
            "
          >
            {{ $t("Dima.fresh.title_2") }}
          </p>
          <p
            class="
              text-white text-base
              md:text-lg
              xl:text-xl
              max-w-2xl
              leading-8
              md:text-justify
            "
          >
            {{ $t("Dima.fresh.titleparagraph_1") }}
          </p>
        </div>
      </div>
    </section>

    <section id="dime" class="mt-12">
      <div
        class="
          max-w-8xl
          mx-auto
          grid
          md:grid-cols-2
          gap-6
          md:gap-12
          px-6
          md:px-8
          lg:px-12
          xl:px-0
        "
      >
        <img src="../assets/images/dima/DIMA_LOGO_LTBLUE.png" alt="" />
        <img src="../assets/images/dima/DIMA_LOGO_PINK.png" alt="" />
      </div>
    </section>

    <section id="Explosion" class="mt-6 md:mt-12">
      <div
        class="
          max-w-8xl
          mx-auto
          grid
          md:grid-cols-2
          gap-6
          md:gap-12
          px-6
          md:px-8
          lg:px-12
          xl:px-0
        "
      >
        <img
          class="h-full w-full object-contain"
          src="../assets/images/dima/explosion.png"
          alt=""
        />

        <div class="">
          <p
            class="
              text-purple-500 text-4xl
              lg:text-6xl
              xl:text-9xl
              font-black
              max-w-2xl
              fontBold
              lg:tracking-ls
            "
          >
            {{ $t("Dima.explosion.title") }}
          </p>

          <p
            class="
              text-purple-500 text-base
              md:text-lg
              xl:text-xl
              max-w-2xl
              leading-8
              md:text-justify
            "
          >
            {{ $t("Dima.explosion.content") }}
          </p>
        </div>
      </div>
    </section>

    <section
      id="gallery"
      class="mt-12 max-w-8xl mx-auto px-6 md:px-8 lg:px-12 xl:px-0"
    >
      <div class="">
        <img src="../assets/images/dima/DIMA_MOCKUPS_TOP1.jpg" alt="" />
      </div>

      <div class="mt-8">
        <img src="../assets/images/dima/DIMA_MOCKUPS_TOP2.jpg" alt="" />
      </div>

      <div class="grid grid-cols-2 gap-8 mt-8">
        <img src="../assets/images/dima/DIMA_BLBERRY_ARTWORK.jpg" alt="" />
        <img src="../assets/images/dima/DIMA_NATURAL_ARTWORK.jpg" alt="" />
      </div>
    </section>

    <section id="footer_project">
      <div class="mt-8 Dima_END_dima bg-fixed"></div>
    </section>

    <div
      class="
        text-xl
        md:text-3xl
        flex
        justify-center
        text-white
        bg-footer
        pt-4
        linkwork
        fontRg
        -mt-1
        -mb-1
        z-10
      "
    >
      <router-link :to="`/${$i18n.locale}/kidsAcademy`">
        {{ $t("Next-Project") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: false,
    };
  },
  mounted() {
    scrollTo(0, 0);
  },
  methods: {
    toggle() {
      if (this.info == true) {
        this.info = false;
        document.body.style.overflow = "";
      } else {
        this.info = true;
        scrollTo(0, 0);
        document.body.style.overflow = "hidden";
      }
    },
  },
};
</script>

<style>
.background-ELEMENTS_dima {
  background-image: url("../assets/images/dima/ELEMENTS_EN.svg");
}
.Dima_END_dima {
  background-image: url("../assets/images/dima/Dima_END.png");
}
</style>